@font-face {
  font-family: 'Jost';
  src: url('https://cdn.einfamilienhaus.de/s/webfonts/jost/jost.woff2')
      format('woff2'),
    url('https://cdn.einfamilienhaus.de/s/webfonts/jost/jost.woff')
      format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'EncodeSans';
  src: url('https://cdn.einfamilienhaus.de/s/webfonts/encode-sans/encode-sans.woff2')
      format('woff2'),
    url('https://cdn.einfamilienhaus.de/s/webfonts/encode-sans/encode-sans.woff')
      format('woff');
  font-style: normal;
  font-weight: 400;
}
