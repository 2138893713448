.PageHeader-CTA-block.PageHeader-CTA-block.PageHeader-CTA-block {
  background-color: var(--colour-secondary);
  border-color: var(--colour-secondary);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.is-scrolled .PageHeader-CTA-block.PageHeader-CTA-block {
  color: var(--colour-white) !important;
}
@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .PageHeader-CTA-block.PageHeader-CTA-block.PageHeader-CTA-block:hover {
    color: var(--text-main-on-dark);
    transform: translateY(-1px);
  }
}

.PageHeader-wrapper.PageHeader-wrapper.PageHeader-wrapper,
.PageHeader-wrapper.PageHeader-wrapper.PageHeader-wrapper--with-rating {
  background-color: var(--colour-white);
  color: var(--colour-primary-600);
  box-shadow: var(--action-card-box-shadow);
}

.PageHeader-nav-link.PageHeader-nav-link {
  text-transform: uppercase;
}

.PageHeader-button.PageHeader-button {
  color: var(--text-main-on-light);
}

.PageHeader-button use,
.PageHeader-button path {
  stroke: var(--colour-primary-600);
}

.PageHeader-wrapper--with-rating .HLP-hero-rating-star use {
  fill: var(--colour-secondary);
}

.HLP-hero-rating-star.HLP-hero-rating-star--empty use {
  fill: var(--colour-neutral-025);
}

.PageHeader-wrapper--with-rating .HLP-hero-rating-star--cut use {
  fill: var(--colour-white);
}

@media (max-width: 767px) {
  .PageHeader-bar.PageHeader-bar--with-rating .PageHeader-logo--EH {
    height: 25px;
  }
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .PageHeader-nav-link:hover.PageHeader-nav-link:hover,
  .PageHeader-button:hover.PageHeader-button:hover {
    text-decoration: none;
    background-color: var(--colour-neutral-025);
    border-radius: var(--border-radius-cta);
  }
}

.PageHeader-nav-link:focus-visible,
.PageHeader-CTA-block:focus-visible,
.PageHeader-button:focus-visible {
  background: none;
  outline: 2px solid var(--colour-primary-600);
}

.is-scrolled .PageHeader-CTA-block:focus-visible {
  outline: 2px solid var(--colour-primary-600);
}
