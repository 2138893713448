:root {
  /* semantic variables */
  --colour-primary-800: #051941;
  --colour-primary-600: #2a3b5d;
  --colour-primary-400: #374767;

  --colour-secondary: #c1202b;

  --colour-tertiary-200: #295ec7;
  --colour-tertiary-400: #0a3ea6;

  --colour-award: #f8bd32;

  --colour-warning: #f7a84a;

  --colour-error: #fd2943;

  --colour-paper-015: #fff9eb;
  --colour-paper-025: #fff0cb;

  --colour-success-025: #dff6ef;
  --colour-success-050: #9be2cd;
  --colour-success-100: #71c9af;
  --colour-success-400: #3d6c5e;

  --colour-neutral-025: #f0f1f2;
  --colour-neutral-050: #d2d4d9;
  --colour-neutral-100: #b6b9c0;
  --colour-neutral-200: #7e8491;
  --colour-neutral-400: #5e6371;
  --colour-neutral-600: #434751;

  --colour-background-010: #fbfcfe;
  --colour-background-015: #f8f9fd;
  --colour-background-025: #f4f6fb;
  --colour-background-050: #c7d5f0;

  --accent-green-200: #1e9575;

  --colour-white: #fff;
  --colour-black: #000;

  /* contextual variables */
  --text-main-on-dark: var(--colour-white);
  --text-main-on-light: var(--colour-primary-600);
  --text-on-secondary: var(--colour-white);
  --text-accent-on-dark: var(--colour-white);
  --text-accent-on-light: var(--colour-secondary);

  /* fonts: START */
  --base-font-size: 16px;
  --base-font-family: 'EncodeSans', Lato, Arial, Roboto, Helvetica, Montserrat,
    'Open Sans';

  --fancy-font-family: 'Jost', Montserrat, Arial, Helvetica, 'Open Sans', Lato;
  /* fonts: END */

  /* elements/CTA: START */
  --cta-primary-hover-box-shadow: 0 6px 12px -8px var(--colour-black),
    0 5px 30px -5px rgba(193, 32, 43, 0.6);
  --cta-primary-hover-box-shadow-dark: 0 4px 16px rgba(0, 0, 0, 0.05);
  --cta-primary-box-shadow: 0 6px 12px -8px var(--colour-black),
    0 5px 30px -5px var(--colour-secondary);
  --cta-primary-box-shadow-with-opacity: 0 6px 18px -15px var(--colour-primary-600),
    0 5px 30px -5px rgba(193, 32, 43, 0.6);
  /* elements/CTA: END */

  --hero-background-overlay: linear-gradient(
    rgba(42, 59, 93, 0.85),
    rgba(42, 59, 93, 0.85)
  );
  --hero-background-overlay-mobile: linear-gradient(
    rgba(42, 59, 93, 0.85) 40%,
    rgba(42, 59, 93, 0.85) 55%
  );
  --hero-background-overlay-bar-tiles: linear-gradient(
    rgba(42, 59, 93, 0.4),
    rgba(42, 59, 93, 0.4)
  );

  --quiz-step-transition-background: rgba(244, 246, 251, 0.9);
  /*background025 colour with 0.9 opacity*/

  /* templates/PageFooter: START */
  --page-footer-background-top: var(--colour-primary-400);
  --page-footer-background-mid: var(--colour-primary-600);
  --page-footer-background-bottom: var(--colour-primary-800);

  --page-footer-text: var(--colour-white);
  --page-footer-text-link: var(--colour-white);
  --page-footer-text-link-alt: var(--colour-white);
  /* templates/PageFooter: END */

  --search-result-background: var(--colour-background-010);
  --radio-checked-color: var(--colour-secondary);

  --guidebook-activation-modal-shadow: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(193, 32, 43, 0.6)
  );
}
